<template>
  <input 
  type="file" 
  multiple
  class="file-input" 
  accept="image/*" 
  ref="filePicker" 
  @change="handleFiles">
  <v-card 
  v-if="files.length === 0" 
  max-width="90%" 
  width="400px"
  class="float-middle">
    <v-toolbar density="compact">
      <v-toolbar-title class="font-weight-light">
        New Document
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
    <v-sheet class="pa-4">
      <h3 class="text-center mb-4 text-light font-weight-light">
        To create a document, select images from your device
      </h3>
      <v-btn 
      block 
      color="green" 
      text="Select Images" 
      prepend-icon="mdi-camera"
      @click="$refs.filePicker.click()"/>
    </v-sheet>
  </v-card>
  <v-card 
  v-else
  width="100%" 
  class="mb-4">
    <v-row no-gutters>
      <v-col cols="6">
        <v-btn 
        rounded="0"
        block
        color="blue"
        prepend-icon="mdi-download" 
        text="Generate PDF"
        @click="createPDF"/>
      </v-col>
      <v-col cols="6">
        <v-btn 
        rounded="0"
        block
        color="green"
        prepend-icon="mdi-plus" 
        text="Add Image"
        @click="$refs.filePicker.click()"/>
      </v-col>
    </v-row>
    
    <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
  </v-card>
</template>


<script>
import { mapState } from 'pinia';
import {usePDFCreatorStore} from "@/store/pdf-creator"
export default {
  computed: {
    ...mapState(usePDFCreatorStore, ['files', 'isLoading'])
  },
  methods: {
    showFilePicker() {
      this.$refs.filePicker.click()
    },
    createPDF() {
      const store = usePDFCreatorStore();
      store.createPDF();
    },
    handleFiles(e) {
      e.preventDefault();
      e.stopPropagation();
      const files = [...e.target.files];
      const store = usePDFCreatorStore();
      files.forEach(async (file) => await store.addEntry(file));
    },
  }
}
</script>

<style>
.file-input {
  visibility: hidden;
  position:fixed;
  top:0px;
  left:0px;
}
.float-middle {
  z-index:10000;
  position:fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
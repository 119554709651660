<template>
  <v-dialog
    v-if="!scanner"
    :model-value="true"
    max-width="320"
    persistent
    opacity="0"
  >
    <v-list
      class="py-2"
      color="primary"
      elevation="12"
      rounded="lg"
    >
      <v-list-item
        title="Starting scanner... Please Wait"
      >
        <template v-slot:append>
          <v-progress-circular
            color="primary"
            indeterminate="disable-shrink"
            size="16"
            width="2"
          ></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>
  </v-dialog>
  <v-sheet color="transparent" class="mb-4" v-else-if="!generatingPDF && !pdfBlob">
    <ImageInput />
    <DocumentFiles />
  </v-sheet>
  <PDFSaver v-else-if="pdfBlob" />
  <PDFLoader v-else />
</template>

<script>
import {usePDFCreatorStore} from '@/store/pdf-creator'
import { mapState } from 'pinia'
import DocumentFiles from './DocumentFiles.vue'
import ImageInput from "./ImageInput.vue"
import PDFLoader from "./PDFLoader.vue"
import PDFSaver from "./PDFSaver.vue"
export default {
  mounted() {
    const store = usePDFCreatorStore();
    store.initialize();
  },
  components: {
    DocumentFiles,
    ImageInput,
    PDFLoader,
    PDFSaver
  },
  computed :{
    ...mapState(usePDFCreatorStore, ['generatingPDF', 'pdfBlob', 'scanner'])
  },
  methods: {
    createPDF() {
      const store = usePDFCreatorStore();
      store.createPDF();
    },
  }
}
</script>
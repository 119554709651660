<template>
  <CamScanner />
</template>

<script>
import CamScanner from '@/components/scanner/CamScanner.vue';

export default {
  name: 'ScannerView',
  components: {
    CamScanner
  }
}
</script>

import { v4 as uuid } from "uuid";


export default class Scanner {

  constructor(file) {
    this.status = "NOT_LOADED";
    this.jobs = {};
    this.file = file;
    this.load();
  }

  load() {
    return new Promise((resolve, reject) => {
      if (this.status === "READY") {
        resolve(this.worker);
        console.log('opencv loaded');
        return;
      }
      else if (this.status !== "LOADING") {
        this.status = "LOADING";
        this.worker = new Worker(new URL('./image-worker.js', import.meta.url));
        this.worker.onmessage = this.onMessage.bind(this);
        this.worker.onerror = reject;
        this.worker.postMessage({ msg: 'load' });
      }
      const interval = setInterval(() => {
        if (this.status === "READY") {
          clearInterval(interval);
          resolve(this.worker);
        }
      }, 100);
    })
  }

  onMessage(e) {
    switch (e.data.msg) {
      case 'load': {
        this.status = "READY";
        if (this.onload) this.onload()
        break;
      }
      case 'processImage': {
        this.jobs[e.data.id] = e.data;
        break;
      }
      case 'generateThumbnail': {
        this.jobs[e.data.id] = e.data;
        break;
      }
      case "error": {
        console.log('error recieved from worker', e.data.error)
      }
    }
  }


  waitForJobToFinish(jobId, waitTimeMs=120000, stepTimeMs=250) {
    return new Promise((resolve, reject) => {
      let timeSpentMs = 0;
      let interval = setInterval(() => {
        const limitReached = timeSpentMs > waitTimeMs;
        const { status } = this.jobs[jobId];
        if (status === 'completed') {
          clearInterval(interval);
          resolve(this.jobs[jobId]);
          delete this.jobs[jobId];
        } else if (status === 'failed') {
          clearInterval(interval)
          reject(this.jobs[jobId])
          delete this.jobs[jobId]
        } else if (limitReached) {
          clearInterval(interval);
          reject("Processing timed out");
          delete this.jobs[jobId]
        } else {
          timeSpentMs += stepTimeMs;
        }
      }, stepTimeMs);
    });
  }

  processImage(entry, canvas, maxDimension=3000, imageData=false) {
    const { 
      file, 
      detectEdges, 
      adaptiveThreshold, 
    } = entry;
    const controller = canvas.transferControlToOffscreen();
    console.log('creating processing job for', file.name);
    const jobId = uuid();
    const job = { 
      id: jobId, 
      msg: 'processImage',
      file,
      detectEdges,
      adaptiveThreshold,
      canvas:controller,
      maxDimension,
      imageData
    };
    this.worker.postMessage(job, [controller]);
    this.jobs[jobId] = job;
    return this.waitForJobToFinish(jobId);
  }

}
<template>
   <h1>Privacy Policy for Free Cam Scanner</h1>
    <p>Thank you for using Free Cam Scanner! Your privacy is important to us. This Privacy Policy explains how we collect, use, and safeguard your personal information when you visit our website, free-cam-scanner.com, and use our services.</p>
    
    <h2>Information We Collect</h2>
    <p>When you use Free Cam Scanner, we may collect basic profile information including your email address, first name, last name, and profile picture. We collect this information when you register for an account on our website or when you choose to provide it voluntarily.</p>

    <h2>How We Use Your Information</h2>
    <p>We use the information we collect to provide, maintain, and improve our services. Specifically, we may use your email address to communicate with you about your account or to send you updates and promotions related to Free Cam Scanner. Your first name, last name, and profile picture may be displayed within the app to personalize your experience.</p>

    <h2>Protection of Your Information</h2>
    <p>We take the security of your personal information seriously and have implemented appropriate technical and organizational measures to protect it against unauthorized access, alteration, disclosure, or destruction.</p>

    <h2>Sharing Your Information</h2>
    <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted third-party service providers who assist us in operating our website and providing our services, as long as those parties agree to keep this information confidential.</p>

    <h2>Your Rights</h2>
    <p>You have the right to access, correct, or delete the personal information we hold about you. You may also choose to unsubscribe from our marketing communications at any time by following the instructions provided in those communications.</p>

    <h2>Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically for any changes.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:contact@free-cam-scanner.com">contact@free-cam-scanner.com</a>.</p>

    <p>This Privacy Policy was last updated on 4/10/2024.</p>
</template>

<script>
export default {}
</script>
<template>
  <v-card class="pa-0 mb-2">
    <v-toolbar density="compact">
      <v-toolbar-title class="font-weight-light">{{ file.name }}</v-toolbar-title>
      <v-spacer />
      <v-btn class="drag mr-1" icon>
        <v-icon>mdi-drag</v-icon>
      </v-btn>
    </v-toolbar>
    <v-layout class="pa-2" style="min-height: 160px;">
      <v-navigation-drawer
        color="transparent"
        :value="true"
        :rail="true"
        permanent
        location="right"
        floating
      >
        <v-list density="compact" nav>

          <v-list-item 
            v-if="!loading"
            @click="adaptiveThreshold = !adaptiveThreshold" 
            :active="adaptiveThreshold"
            prepend-icon="mdi-image-filter-black-white" title="Adaptive Threshold">
          </v-list-item>

          <v-list-item 
            v-if="!loading"
            @click="edgeDetection = !edgeDetection"
            :active="edgeDetection"
            prepend-icon="mdi-crop" title="Detect Edges">
          </v-list-item>

          <v-progress-circular v-if="loading" color="blue" indeterminate></v-progress-circular>

          <v-divider />

          <v-dialog
            width="auto"
            v-model="deleteDialog"
          >
            <template v-slot:activator="{ props: activatorProps }">
              <v-list-item 
                color="red"
                class="text-red"
                v-if="!loading"
                v-bind="activatorProps"
                prepend-icon="mdi-delete" title="Delete Image">
              </v-list-item>
            </template>

            <template v-slot:default="{ isActive }">
              <v-card>
                <v-toolbar>
                  <v-toolbar-title>
                    Remove {{ file.name }}
                  </v-toolbar-title>
                  <v-spacer />
                  <v-btn
                    icon="mdi-close"
                    @click="isActive.value = false"
                  ></v-btn>
                </v-toolbar>

                <v-card-text class="pa-12">
                  Are you sure you want to remove this page?
                </v-card-text>

                <v-card-actions class="justify-end">
                  <v-btn
                    color="red"
                    text="Remove Image"
                    @click="isActive.value = false;deleteFile()"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>

        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-row justify="center" align-content="center">
          <v-col>
          <div ref="canvasContainer" class="canvas-container">
          </div>
          </v-col>
        </v-row>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import {usePDFCreatorStore} from '@/store/pdf-creator';

export default {
  mounted() {
    if (this.canvas)
      this.insertThumbnail()
  },
  props: ['value'],
  data() {
    return {
      canvasSampleSize: 1000,
      error: null,
      loading: null,
      deleteDialog: false,
      settingsDialog: false
    }
  },
  computed: {
    canvas: {
      get() {return this.item.canvas},
      set(v) {
        if (this.item.canvas)
          delete this.item.canvas;
        this.item.canvas = v;
      }
    },
    adaptiveThreshold: {
      get() {return this.item.adaptiveThreshold || false},
      set(v) {this.item.adaptiveThreshold = v; this.generatePreview()}
    },
    edgeDetection: {
      get() {return this.item.detectEdges || false},
      set(v) {this.item.detectEdges = v; this.generatePreview()}
    },
    result() {
      return this.item.result;
    },
    file() {
      return this.item.file
    },
    item: {
      get() {return this.value},
      set(v) {this.$emit('input', v)}
    }
  },
  watch: {
    canvas() {
      this.insertThumbnail();
    },
  },
  methods: {
    insertThumbnail() {
      if (!this.canvas) return;
      const container = this.$refs.canvasContainer;
      while (container.firstChild)
        container.removeChild(container.firstChild);
      this.canvas.style.maxWidth = "100%";
      this.canvas.style.maxHeight = "500px";
      container.appendChild(this.canvas);
    },
    deleteFile() {
      const store = usePDFCreatorStore();
      store.removeEntry(this.item);
      this.dialog = false;
      console.log("delete", this.item);
    },
    async generatePreview() {
      this.loading = true;
      const store = usePDFCreatorStore();
      await store.generatePreview(this.item, this.canvasSampleSize);
      this.loading = false;
    },
  }
}
</script>

<style>
.canvas-container {
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>
<template>
  <v-dialog :model-value="pdfBlob" max-width="500">
    <v-card rounded="lg">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-medium-emphasis ps-2">
          Save PDF
        </div>

        <v-btn
          icon="mdi-close"
          variant="text"
          @click="deletePDF"
        ></v-btn>
      </v-card-title>

      <v-divider class="mb-4"></v-divider>

      <v-card-text>
        <div class="text-medium-emphasis mb-4">
          Your document has been created. Click "Download PDF" to save your file.
        </div>

        <div class="g_id_signin"
            data-type="standard"
            data-size="large"
            data-theme="outline"
            data-text="sign_in_with"
            data-shape="rectangular"
            data-logo_alignment="left">
        </div>


      </v-card-text>

      <v-divider class="mt-2"></v-divider>

      <v-card-actions class="my-2 d-flex justify-end">
        <v-btn
          class="text-none"
          rounded="xl"
          text="Cancel"
          @click="deletePDF"
        ></v-btn>

        <v-btn
          class="text-none"
          color="green"
          rounded="xl"
          text="Save"
          variant="flat"
          @click="downloadFile"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { usePDFCreatorStore } from '@/store/pdf-creator';
import { mapState } from "pinia"
export default {
  computed: {
    ...mapState(usePDFCreatorStore, ['pdfBlob'])
  },
  methods: {
    deletePDF() {
      const store = usePDFCreatorStore();
      URL.revokeObjectURL(store.pdfBlob);
      store.pdfBlob = null;
    },
    downloadFile() {
      const store = usePDFCreatorStore();
      // Assuming 'blob' is your Blob object
      var blob = new Blob([store.pdfBlob], {type: "application/pdf"});

      // Create a URL for the Blob
      var url = URL.createObjectURL(blob);

      window.open(url, null);
      // Clean up by revoking the URL
      URL.revokeObjectURL(url);

    }
  }
}
</script>
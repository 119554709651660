import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import { createPinia } from 'pinia'
import './registerServiceWorker'
import Ads from 'vue-google-adsense'

loadFonts()

const pinia = createPinia()



createApp(App).use(router)
  .use(vuetify)
  .use(pinia)
  .use(require('vue-script2'))
  .use(Ads.Adsense)
  .use(Ads.InArticleAdsense)
  .use(Ads.InFeedAdsense)
  .mount('#app')

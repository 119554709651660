<template>
  <v-app id="inspire" theme="dark">

    <v-app-bar>
      <v-app-bar-nav-icon>
        <v-avatar
          image="/img/logo.png"
          rounded="0"
        ></v-avatar>
      </v-app-bar-nav-icon>
      <v-app-bar-title class="logo-font ml-2">Free Cam Scanner</v-app-bar-title>
      <v-spacer />
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>

    <div class="floating-messages">
      <v-alert
        density="compact"
        v-for="alert, index in messages"
        :key="'alert' + index"
        border="start"
        :color="alert.type || 'warning'"
        closable
        :text="alert.message"
        @click="removeAlert(alert)"
      >
      </v-alert>
    </div>

  </v-app>
</template>

<script>
import { usePDFCreatorStore } from './store/pdf-creator';
import { mapState } from 'pinia';
export default {
  mounted() {
    document.title = 'Free Cam Scanner';
    const store = usePDFCreatorStore();
    store.initialize()
  },
  data: () => ({ drawer: false }),
  computed: {
    ...mapState(usePDFCreatorStore, ['messages'])
  },
  methods: {
    removeAlert(alert) {
      const store = usePDFCreatorStore();
      store.clearAlert(alert.id)
    }
  }
}
</script>

<style>
.logo-font {
  font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.floating-messages {
  position:fixed;
  width:100%;
  bottom: 0px;
  left:0px;
}
</style>
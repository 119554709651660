import { createRouter, createWebHashHistory } from 'vue-router'
import ScannerView from '../views/ScannerView.vue'
import PrivacyPolicyView from "../views/PrivacyPolicyView.vue"

const routes = [
  {
    path: '/',
    name: 'Scanner',
    component: ScannerView,
    meta: {
      title: 'Free Cam Scanner',
      metaTags: [
        {
          name: 'description',
          content: '"Free Cam Scanner" is a versatile mobile application designed to streamline the process of converting a series of camera phone images into a single PDF document. With its intuitive interface and powerful functionality, users can effortlessly transform their collection of snapshots into professionally formatted PDF files with just a few taps.'
        },
        {
          property: 'og:description',
          content: '"Free Cam Scanner" is a versatile mobile application designed to streamline the process of converting a series of camera phone images into a single PDF document. With its intuitive interface and powerful functionality, users can effortlessly transform their collection of snapshots into professionally formatted PDF files with just a few taps.'
        }
      ]
    },
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    component: PrivacyPolicyView,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

<template>
  <v-sheet color="transparent">
    <draggable 
      v-model="items" 
      @start="drag=true" 
      @end="drag=false"
      handle=".drag" 
      item-key="id">
      <template #item="{element}">
        <DocumentFile :value="element" @input="handleChange">
        </DocumentFile>
      </template>
    </draggable>
  </v-sheet>
</template>

<script>
import DocumentFile from './DocumentFile.vue'
import draggable from 'vuedraggable'
import {usePDFCreatorStore} from '@/store/pdf-creator'
import {mapState} from "pinia"

export default {
  components: {
    draggable,
    DocumentFile
  },
  computed: {
    items: {
      get() {
        return this.files
      },
      set(v) {
        usePDFCreatorStore().files = v
      }
    },
    ...mapState(usePDFCreatorStore, ['files', 'isLoading'])
  },
  methods: {
    handleChange(e) {
      console.log(e)
    }
  },
  data() {
    return {
      drag: false
    }
  }
}

</script>
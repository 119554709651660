import { defineStore } from 'pinia';
import { v4 as uuid4 } from "uuid";
import { jsPDF } from "jspdf";
import Scanner from './pdf-creator/image-dispatch'

const pdfStore = {

  state: () => {
    return {
      scanner: null,
      generatingPDF:false,
      messages: [],
      detectEdges:true,
      adaptiveThreshold:true,
      files: [],
      jobs: [],
      pdfBlob: false
    }
  },

  getters: {
    isLoading() {
      return this.jobs.length !== 0
    }
  },

  actions: {
    initialize() {
      if (this.scanner || this.loading) return;
      this.loading = true;
      const scanner = new Scanner();
      scanner.onload = () => {
        this.scanner = scanner;
        this.loading = false;
      }
    },
    alert(message, type="warning") {
      const id = uuid4();
      this.messages = [
        ...this.messages.filter(i => i.message != message), 
        { id, message, type }
      ];
      setTimeout(() => this.clearAlert(id), 5000);
      return id;
    },
    clearAlert(id) {
      this.messages = this.messages.filter(i => i.id != id)
    },
    async generatePreview(entry, maxDimension=1000) {
      const jobId = this.addJob();
      const canvas = document.createElement('canvas');
      entry.canvas = canvas;
      try {
        await this.scanner.processImage(entry, canvas, maxDimension);
      } catch (e) {
        console.log(e);
        if (e.error)
        this.alert(e.error.message, 'warning');
      }
      this.removeJob(jobId);
      return canvas;
    },
    addJob() {
      const jobId = uuid4();
      this.jobs = [...this.jobs, jobId];
      return jobId;
    },
    removeJob(jobId) {
      this.jobs = this.jobs.filter(i => i !== jobId);
    },
    async addEntry(file) {
      // adds a image file to the pdf page list 
      const entry = {
        file,
        detectEdges: this.detectEdges,
        adaptiveThreshold: this.adaptiveThreshold
      };
      this.files = [...this.files, entry];
      this.generatePreview(entry)
    },
    removeEntry(entry) {
      delete entry.canvas;
      delete entry.controller;
      // removes image file from the pdf page list
      this.files = this.files.filter(i => i !== entry)
    },
    async createPDF() {
      const jobId = this.addJob();
      this.generatingPDF = true;
      const pdf = new jsPDF();
      for (var i = 0; i < this.files.length; i++) {
        const entry = this.files[i];
        const alertId = this.alert(`Processing file ${entry.file.name}`, 'info');
        const canvas = document.createElement('canvas');
        let result;
        try {
          result = await this.scanner.processImage(entry, canvas, 3000, true);
        } catch (e) {
          result = e;
          this.alert(e.error.message, 'warning');
        }
        pdf.setPageWidth(i + 1, canvas.width);
        pdf.setPageHeight(i + 1, canvas.height);
        pdf.addImage(result.imageData, "JPEG", 0, 0, canvas.width, canvas.height, entry.file.name, 'SLOW');
        delete result.imageData;
        if (i < this.files.length - 1) {
          const nextPage = this.files[i + 1];
          pdf.addPage({
            unit: 'px',
            format: [nextPage.width, nextPage.height]
          });
        }
        this.clearAlert(alertId);
      }
      this.pdfBlob = pdf.output('blob');
      for (i = 0; i < this.files.length; i++) {
        pdf.deletePage(1)
      }
      this.alert("Generated PDF", 'success');
      this.removeJob(jobId);
      this.generatingPDF = false;
    }
  },

};

export const usePDFCreatorStore = defineStore('PDFCreator', pdfStore)